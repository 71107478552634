.food-search {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-results {
  margin-top: 10px;
}

.food {
  padding: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
}

.search-results {
  background-color: #17224d;
  padding: 5px;
  border-radius: 10px;
}

.search-button {
  align-self: center;
}

.search-bar {
  min-width: 200px;
}
