#meal-log-container,
.meal {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 1s ease-in-out;
}

.meal {
  width: 100%;
}

.meal-info {
  flex-direction: column;
  min-width: 240px;
  padding: 10px;
}

.meal-log-item,
.meal-info {
  display: flex;
  align-items: center;
}

.meal-item-info {
  flex-direction: column;
  width: 100%;
}

.meal-log-item {
  margin-bottom: 20px;
  flex-direction: column;
  width: 100%;
  background-color: #17224d;
  border-radius: 10px;
  transition: 1s ease-in-out;
}

.meal-buttons {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.add-meal-log-item {
  height: 40px;
}

.update-btn-container {
  text-align: center;
}

.update-meal-button {
  margin-top: 10px;
  background-color: #007fff;
  color: #f5fafa;
}

.meal-foods {
  width: 100%;
  padding: 20px 5px 0 5px;
}

.meal-log-item:first-child {
  margin-top: 0;
}

.meal-log-item:last-child {
  margin-bottom: 0;
}

.empty-log p {
  color: #f5fafa;
}

@media only screen and (min-width: 425px) {
  .meal-info {
    width: 100%;
    flex-direction: row;
  }

  .meal-item-info {
    margin-right: 20px;
  }
}
