.food-items {
  padding: 10px;
  display: flex;
  flex-direction: column;
  color: #17224d;
  font-size: 16px;
}

.foods {
  min-height: 100px;
  background-color: #17224d;
  margin-bottom: 10px;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
  box-shadow: 3px 3px 5px rgba(117, 117, 117, 0.425);
}

.food-item {
  height: 80px;
  margin: 10px;
  display: flex;
  align-items: center;
  background-color: #f5fafa;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
}

.empty {
  justify-content: center;
}

.new {
  justify-content: space-between;
  padding: 10px;
}

.add {
  padding: 0;
  color: white;
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 5px;
  transition: 0.5s ease-in-out;
}

.create-meal {
  width: 100px;
  height: 50px;
  margin: 10px auto 0 auto;
}

.add-meal {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.meal-name {
  margin-bottom: 10px;
}

.error {
  color: red;
  margin-bottom: 10px;
}

.button-container {
  align-self: center;
  display: flex;
  min-width: 280px;
  justify-content: space-between;
}

.button {
  height: 40px;
  width: 100px;
  transition: 0.5s ease-in-out;
  box-shadow: 3px 3px 5px rgba(117, 117, 117, 0.774);
}

.food-item:hover,
.empty:hover {
  box-shadow: 5px 5px #1e1f1f52;
  transform: translateY(-5px);
}

.button:hover {
  transform: translateY(-2px);
}

.delete {
  height: 40px;
  width: 70px;
}
.add-item {
  width: 100px;
}

.create-meal {
  background-color: #007fff;
}

.delete {
  height: 40px;
  width: 70px;
}
.add-item {
  width: 100px;
}
