.overview {
  background-color: #077fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  position: relative;
  height: 300px;
  color: white;
}

.overview-macro {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress-ring__circle {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress-bar {
  display: flex;
  flex-direction: column;
}

svg {
  height: 100px;
  width: 100px;
}

circle {
  transition: 1s ease-in-out;
}

.progress-macro {
  font-size: 28px;
}

#background-circle {
  stroke: #17224d5e;
}

.circle {
  stroke-width: 8;
  cx: 50;
  cy: 50;
  r: 34;
}

@media only screen and (min-width: 1000px) {
  .circle {
    stroke-width: 8;
    cx: 50;
    cy: 50;
    r: 44;
    stroke-dasharray: 276.46015351590177 276.46015351590177;
    transition: 1.5s ease-in-out;
  }
}
