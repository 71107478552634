.App_main {
  margin-top: 50px;
}

.app-header {
  background-color: #f5fafa;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
}

input,
select {
  height: 27px;
  padding-left: 5px;
  border: none;
  box-shadow: 3px 3px 5px rgba(117, 117, 117, 0.425);
  border-radius: 5px;
}

@media only screen and (min-height: 900px) {
  .App_main {
    min-height: calc(100vh - 50px);
  }
}

input,
select {
  height: 27px;
  padding-left: 5px;
}

/*@media only screen and (min-width: 600px) {
  main {
    padding: 0 50px;
  }
}*/
