.pop-up {
  border-radius: 30px;
  position: fixed;
  min-width: 275px;
  max-width: 700px;
  height: 500px;
  background: #17224d;
  right: 0;
  left: 0;
  top: 10.5%;
  margin-right: 10px;
  margin-left: 10px;
  padding: 15px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5);
  opacity: 0;
  animation-name: bounceIn;
  animation-duration: 450ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.component {
  height: 100%;
  overflow: auto;
}

.component::-webkit-scrollbar {
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  width: 5px;
}

.component::-webkit-scrollbar-thumb {
  background-color: white;
  width: 5px;
  border-radius: 5px;
}

.close-window {
  position: absolute;
  background: red;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  right: -10px;
  top: -10px;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.06);
  }
  80% {
    opacity: 1;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

@media only screen and (min-width: 600px) {
  .pop-up {
    margin: 0 auto;
    width: 600px;
  }
}

@media only screen and (min-height: 700px) and (max-height: 999px) {
  .pop-up {
    height: 650px;
  }
}

@media only screen and (min-height: 1000px) {
  .pop-up {
    height: 850px;
  }
}
