html {
  height: 100vh;
}

* {
  box-sizing: border-box;
  font-family: "Barlow Semi Condensed", sans-serif;
}

body {
  margin: 0;
  color: #17224d;
  background-color: #f5fafa;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  border: none;
  border-radius: 10px;
  background-color: #17224d;
  color: white;
  font-size: 16px;
}
