.food-img {
  width: 60px;
  height: 60px;
}

.food-info {
  min-width: 125px;
  margin-right: 20px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.food-log-item-name {
  min-width: 500px;
  margin-top: 0;
}

.macro {
  margin: 0;
}

@media only screen and (max-width: 450px) {
  .scroll {
    animation: floatText 10s infinite linear;
    -webkit-animation-delay: 1.75s;
  }
}

@keyframes floatText {
  0%,
  10% {
    transform: translateX(0);
  }
  90%,
  100% {
    transform: translateX(-120%);
  }
}
