.footer {
  border-top: 1px solid #17224d5e;
  height: 150px;
  max-width: 1420px;
  align-self: center;
  margin: 60px auto;
  display: flex;
  justify-content: space-around;
}

li {
  list-style: none;
  margin: 10px 0;
}

ul {
  padding: 0;
}

@media only screen and (min-width: 775px) {
  .footer {
    margin: 60px 20px;
  }
}

@media only screen and (min-width: 1420px) {
  .footer {
    margin: 60px auto;
  }
}
