#food-log {
  height: 100%;
}

.food-item-container {
  margin: 20px 0;
  padding: 10px;
  flex-direction: row;
  height: 120px;
  display: flex;
  align-items: center;
  background-color: #f5fafa;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
}

.food-item-container:first-child {
  margin-top: 0;
}

.food-item-container:last-child {
  margin-bottom: 0;
}

.food-macros {
  margin: 0;
  justify-content: space-between;
}

.hidden {
  display: none;
}

.add-food {
  flex-direction: column;
  width: 30%;
}

.food-macros,
.food-item-container,
.add-food {
  display: flex;
}

.empty-log {
  color: #f5fafa;
}

@media only screen and (min-width: 400px) {
  .food-item-container {
    justify-content: space-between;
  }
}
