.sign-in-form {
  flex-grow: 0.3;
  display: flex;
  flex-direction: column;
}

.sign-in-form-name {
  margin: 10px 0;
  width: 80%;
}

.sign-in {
  flex-grow: 0.3;
  padding: 10px;
  min-width: 300px;
  max-width: 1000px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

#sign-in-button {
  margin-top: 10px;
}

.button {
  align-self: center;
}

#user,
#password.sign-in-input {
  margin-bottom: 10px;
  width: 80%;
}

.red {
  color: red;
}

.sign-in-input {
  margin-bottom: 15px;
}

.button {
  align-self: center;
}

.input {
  margin-bottom: 10px;
}

.red {
  color: red;
}
